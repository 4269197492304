.dotted {
  border-style: dashed;
  margin: 2rem 0;
}

.separa {
  background: #cacaca;
  margin: 25px 0 25px 0;
  padding: 20px;
  text-align: center;
}
