
//  Settings
@import "global/settings"; // Foundation settings file.

// Foundation mixins & browser resets
@import 'foundation';

// Third-party libraries
// ver si la vamos a usar@import 'font-awesome';
@import 'motion-ui';

// Foundation global styles
@include foundation-global-styles;

// Individual foundation components
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-progress-element;
@include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-menu-icon;
// @include foundation-flex-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Modules
@import "modules/navigation";
@import "modules/footer";
@import "modules/sidebar";

// Components
@import "components/buttons";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";
@import "components/page-builder";
@import "components/preloader";
@import "components/parallax";
@import "components/image_hover_effects";
@import "components/hero";

// Templates
@import "templates/single-post";
@import "templates/index";


// Typography
@import "typography/base";
