.feature-image {
  padding: 20px 0;
}

.image-right {
  padding: 20px 0;
  float: right;
}

.image-left {
  padding: 20px 0;
  float: left;
}

.features {
  padding-top: 75px;

}
