@include breakpoint(small) {
	#featured-hero {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin: 0 0 rem-calc(32);
		position: relative;
		height: rem-calc(200);
		}

		.no-featured-image-set {
			height: rem-calc(16) !important;
		}
}

@include breakpoint(medium) {
	#featured-hero {
		height: rem-calc(400);
		}
}
