.sites-button {
  background: #FF6908;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}
a.home-hero-button {
  background: transparent;
  font-size: 1.7rem;
  color: $white;
  border: 1px solid $white;
  padding: 15px;
}


a.home-hero-button:hover {
  background-color: rgba(254, 254, 254, 0.19);
  color: $global-logo-color;
}
