// Generic fonts
p {
    line-height: 1.2;
    margin-bottom: 1rem;
    text-rendering: optimizeLegibility;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', Arial;
    font-weight: 400;
	font-size: 1.2rem;
}

h2 {
    margin-top: 60px;
}

h3 {
    margin-top: 20px;
}

.feature-image {
  padding: 20px 0;
}

.image-right {
  padding: 20px 0;
  float: right;
}

.image-left {
  padding: 20px 0;
  float: left;
}

.features {
  padding-top: 75px;

}
