.sidebar {
  @include breakpoint(small) {
    @include grid-column(12);
  }

  @include breakpoint(medium) {
    @include grid-column(4);
    margin-top: 1rem;
  }
}
