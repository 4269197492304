
.hero {
  background: url("/assets/img/home-hero-banner/helptext-refugee-hero.jpg") no-repeat center center;
  background-size: cover;
  height: 100vh;
  &:before {
    content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom right,#121212,#101010);
		opacity: .4;
  }
  .intro {
    padding: 3rem;
    position: relative;
    top: 65%;
    transform: translateY(-50%);
  }
  h1 {
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    font-weight: 300;
    text-align: center;
  }
  p {
    color: #fff;
    line-height: 1.75em;
    font-weight: 200;
    text-align: center;
    margin-bottom: 2rem;
  }
  .tech-img {
    background: url('/assets/img/home-hero-banner/helptext-help-sms.jpg') no-repeat center center;
    background-size: cover;
    height: 9rem;
    width: 9rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }
  @media only screen and (min-width: 40.063em) {
    .tech-img {
      height: 18rem;
      width: 18rem;
    }
    h1 {
      padding-top: 4.5rem;
      text-align: right;
      font-size: 2.5rem;
    }
    p {
      text-align: right;
    }
  }
}
